import {
  get,
  post,
  patch,
  deleteRequest,
  postFormData,
  fake,
  download,
} from "./api-core";

const participationParams = ({ filter }) => {
  let filterParams = filter.quizzes.map((id) => `quiz_ids[]=${id}`);
  filterParams = filterParams.concat(
    filter.years.map((year) => `years[]=${year}`)
  );
  filterParams = filterParams.concat(
    filter.companyGroups.map((id) => `group_ids[]=${id}`)
  );
  if (filter.spouses) {
    filterParams.push("spouses=1");
  }
  if (filter.employees) {
    filterParams.push("employees=1");
  }

  filterParams.push(`user_type=${filter.userType}`);

  return filterParams.join("&");
};

const endpoints = {
  auth: {
    login: (params) => post("authentication/login", params),
    verify: () => get("authentication/check_auth", {}),
    getProfile: () => get("authentication/user_profile", {}),
    setInitialPassword: ({ password }) =>
      post("unified_authentication/set_password", { password }),
  },
  summaryDashboard: () => get("admin/summary_dashboard", {}),
  dashboard: {
    get: () => fake({ name: "Dashboard" }),
  },
  email: {
    get: () => fake({ name: "Email" }),
  },
  questions: {
    archive: ({ id }) => post(`questions/${id}/archive`, {}),
    delete: ({ id }) => post(`questions/${id}/delete`, {}),
  },
  questionsSearch: {
    get: () => get("question_search", {}),
    post: (params) => post("question_search", params),
    updateQuestion: (params) => post("update_question", params),
    searchById: (params) => post("question_search/search_by_id", params),
    searchByIds: (params) => post("question_search/search_by_ids", params),
    fromTemplate: (params) =>
      post("question_search/questions_from_template", params),
  },
  similarQuestions: {
    get: ({ page }) => get(`admin/similar_questions?page=${page}`, {}),
  },
  bannerImages: {
    get: () => get("admin/banner_images", {}),
  },
  quizManager: {
    questionSearch: {
      post: (params) => post("quiz_manager_question_search", params),
    },
    initialQuestions: {
      get: () => get("quiz_manager_initial_questions", {}),
    },
    checkForRepeatQuestions: {
      get: ({ companyIds, questionIds, quizId }) =>
        get("admin/quiz_manager/repeat_questions", {
          params: {
            company_ids: companyIds,
            question_ids: questionIds,
            quiz_id: quizId,
          },
        }),
    },
  },
  quizzes: {
    index: (params) => get("admin/quizzes", { params }),
    completions: () => get("admin/quizzes/completions", {}),
    get: ({ id }) => post("admin/quiz_creator_state", { id }),
    alternates: () => get("admin/autopilot/alternate_quizzes", {}),
    create: (params) => post("admin/quizzes", params),
    update: (params) => patch(`admin/quizzes/${params.id}`, params),
    addToCompany: (params) =>
      post("admin/autopilot/add_quiz_to_company", params),
    delete: (id) => post("admin/quizzes/delete", { id }),
    questions: (id) => get(`admin/quizzes/${id}/questions`, {}),
  },
  templates: {
    get: () => get("admin/quiz_templates", {}),
    create: (params) => post("admin/quiz_templates", params),
    update: (params) => patch(`admin/quiz_templates/${params.id}`, params),
  },
  companies: {
    list: () => get("admin/companies", {}),
    createOrUpdate: (params) => post("admin/companies", params),
    createAutopilot: (params) => post("admin/autopilot/create", params),
    show: ({ id }) => get(`admin/companies/${id}`, {}),
    getCompanyTemplates: () => get(`admin/companies/templates`, {}),
    endScreens: ({ id }) => get(`admin/companies/${id}/end_screens`, {}),
    updateEndScreens: ({ id, endScreens }) => patch(`admin/companies/${id}/end_screens`, { endScreens }),
    salesSettings: ({ companyId }) => get(`admin/companies/${companyId}/sales_settings`, {}),
    updateSalesSettings: ({ companyId, allowCrossCompanyAccess }) => patch(`admin/companies/${companyId}/sales_settings`, { allowCrossCompanyAccess })
  },
  notes: {
    index: (params) => get("admin/notes", { params }),
    variants: () => get("admin/notes/variants", {}),
    create: (params) => post("admin/notes", params),
    update: (params) => patch(`admin/notes/${params.id}`, params),
    destroy: (id) => deleteRequest(`admin/notes/${id}`, {}),
  },
  reports: {
    togo: {
      index: ({ companyId }) =>
        get(`admin/togo/analytics?company_id=${companyId}`, {}),
    },
    quiz: {
      summary: {
        get: ({ id }) => get(`admin/quizzes/${id}/summary`, {}),
      },
      links: {
        get: ({ id }) => get(`admin/quizzes/${id}/links`, {}),
      },
      questionDetails: {
        get: ({ id }) => get(`admin/quizzes/${id}/question_details`, {}),
      },
      table: {
        get: ({ id }) => get(`admin/quizzes/${id}/table`, {}),
      },
      preview: {
        get: ({ id }) => get(`admin/quizzes/${id}/preview`, {}),
      },
    },
    participation: ({ companyId, page, pageSize, filter }) => {
      let filterParamsAsQuery = participationParams({ filter });
      filterParamsAsQuery =
        filterParamsAsQuery.length > 0 ? "&" + filterParamsAsQuery : "";
      const url = `admin/reports/participation/${companyId}?page=${
        page + 1
      }&page_size=${pageSize}${filterParamsAsQuery}`;

      return get(url, {});
    },
    exportParticipation: ({ companyId, filter }) => {
      const filterParamsAsQuery = participationParams({ filter });
      const url = `admin/reports/participation/${companyId}/export?${filterParamsAsQuery}`;

      return get(url, {});
    },
  },
  doctorCribs: {
    list: () => get("admin/doctor_cribs", {}),
    create: (params) => post("admin/doctor_cribs", params),
    update: (params) => patch(`admin/doctor_cribs/${params.id}`, params),
    delete: ({ id }) => deleteRequest(`admin/doctor_cribs/${id}`, {}),
    reorder: ({ ordered }) => post(`admin/doctor_cribs/reorder`, { ordered }),
    download: () => download("admin/doctor_cribs/download"),
  },
  activity: {
    list: (params) => get("admin/webhook_results", { params }),
  },
  users: {
    list: ({ page, perPage, searchQuery: { query, companyId } }) =>
      get(
        `admin/users?page=${page}&per_page=${perPage}&query=${query}&company_id=${companyId}`,
        {}
      ),
    profile: ({ id }) => get(`admin/users/${id}/profile`, {}),
    availableCompanyGroups: (companyId) =>
      get(`admin/users/available_company_groups?company_id=${companyId}`, {}),
    availableCompanies: () => get("admin/users/available_companies", {}),
    create: (params) => post(`admin/users`, params),
    update: (params) => patch(`admin/users/${params.id}`, params),
    currentPermissions: (userId) =>
      get(`admin/users/${userId}/current_permissions`, {}),
    availablePermissions: (userId) =>
      get(`admin/users/${userId}/available_permissions`, {}),
    grantPermission: (userId, attributes) =>
      post(`admin/users/${userId}/grant_permission`, attributes),
    revokePermission: (userId, attributes) =>
      post(`admin/users/${userId}/revoke_permission`, attributes),
    archive: ({ userId, shouldExcludeAll, excludeAfter }) =>
      post(`admin/users/${userId}/archive`, {
        should_exclude_all: shouldExcludeAll,
        exclude_after: excludeAfter,
      }),
    unarchive: ({ userId }) => post(`admin/users/${userId}/unarchive`, {}),
    softDelete: ({ userId, shouldExcludeAll, excludeAfter }) =>
      deleteRequest(`admin/users/${userId}`, {
        should_exclude_all: shouldExcludeAll,
        exclude_after: excludeAfter,
      }),
    reviewMassArchive: ({ selector, payload }) =>
      post(`admin/users/review_mass_archive`, { selector, payload }),
    massArchive: ({ userIds, shouldExcludeAll, excludeAfter }) =>
      post(`admin/users/mass_archive`, {
        user_ids: userIds,
        should_exclude_all: shouldExcludeAll,
        exclude_after: excludeAfter,
      }),
  },
  preventConsentContent: {
    get: () => get("admin/prevent_consent_content", {}),
    update: ({ payload, header, bullets }) =>
      patch("admin/prevent_consent_content", { payload, header, bullets }),
  },
  questionReview: {
    all: ({ currentFilter }) =>
      get(`admin/question_reviews?filter=${currentFilter}`, {}),
    reviewable: () => get("admin/question_reviews/reviewable", {}),
    history: (id) => get(`admin/question_reviews/${id}/history`, {}),
    downloadTextDocument: (ids) =>
      get(`quiz_to_doc/download_quiz`, { params: { ids: ids.join(",") } }),
    actions: {
      comment: ({ id, comment }) =>
        post(`admin/question_reviews/${id}/comment`, { comment }),
      approve: ({ id, comment }) =>
        post(`admin/question_reviews/${id}/approve`, { comment }),
      approveConditionally: ({ id, comment, approved_update }) =>
        post(`admin/question_reviews/${id}/approve_conditionally`, {
          comment,
          approved_update,
        }),
      acceptEdits: (id, values) =>
        post(`admin/question_reviews/${id}/accept_edits`, values),
      requestReview: ({ id, comment }) =>
        post(`admin/question_reviews/${id}/request_review`, { comment }),
      markAsNotApplicable: ({ id, comment }) =>
        post(`admin/question_reviews/${id}/mark_as_not_applicable`, {
          comment,
        }),
    },
  },
  questionSwap: {
    get: ({ id }) => get(`admin/question_swaps/${id}`, {}),
    swap: ({ id, replacementQuestionId, quizIds }) =>
      patch(`admin/question_swaps/${id}/swap`, {
        replacement_question_id: replacementQuestionId,
        quiz_ids: quizIds,
      }),
    remove: ({ id, quizIds }) =>
      patch(`admin/question_swaps/${id}/remove`, { quiz_ids: quizIds }),
  },
  emails: {
    generalData: () => get("admin/emails/general_data", {}),
    companyDetails: ({ companyId }) =>
      get(`admin/emails/company_details?company_id=${companyId}`, {}),
    targetDetails: ({ companyId, audience, quizId }) =>
      get(
        `admin/emails/target_details?company_id=${companyId}&audience=${audience}&quiz_id=${quizId}`,
        {}
      ),
    sendEmail: (params) => post("admin/emails/send_email", params),
    recipients: ({ companyId }) =>
      get(`admin/companies/${companyId}/email_deliveries/recipients`, {}),
    deliveries: ({ companyId }) =>
      get(`admin/companies/${companyId}/email_deliveries`, {}),
    delivery: ({ companyId, deliveryId }) =>
      get(`admin/companies/${companyId}/email_deliveries/${deliveryId}`, {}),
    overallStatistics: ({ filters }) => {
      const searchParams = new URLSearchParams();
      filters.selectedCompanyIds.forEach(companyId => searchParams.append('company_ids[]', companyId));
      searchParams.append('period_selector', filters.period);

      return get(`admin/email_statistics?${searchParams.toString()}`, {});
    },
  },
  googlePlaces: {
    searchByLocation: (params) =>
      get("google_places/search_by_location", { params }),
    findPlace: (params) => get("google_places/search", { params }),
  },
  trackedEmails: {
    getAddress: () => get("consent/tracked_email_address/get_address", {}),
  },
  consentEmails: {
    index: () => get("admin/consent_emails", {}),
    create: (params) => post("admin/consent_emails", params),
  },
  images: {
    upload: ({ key, file }) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("key", key);
      return postFormData("admin/images", formData);
    },
  },
  pilot: {
    selfSignupAdmin: (params) => post("admin/pilot/self_signup_admin", params),
    createCompany: (params) => post("admin/pilot/create_company", params),
    getQuizzes: () => get("admin/pilot/quizzes", {}),
    getUsers: () => get("admin/pilot/users", {}),
    accountStatus: () => get("admin/pilot/account_status", {}),
    inviteParticipants: (params) =>
      post("invitations/invite_participants_to_pilot", params),
  },
  terms: {
    getLatest: () => get("terms_and_conditions/latest", {}),
    agree: (params) => post("terms_and_conditions/agree", params),
  },
  spellCheck: {
    checkQuiz: (quiz_id) =>
      get("admin/spell_check/check_quiz_async", { params: { quiz_id } }),
  },
  adminFiles: {
    list: () => get("admin/files", {}),
    upload: ({ name, file }) => {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("file", file);

      return postFormData("admin/files", formData);
    },
    remove: ({ id }) => deleteRequest(`admin/files/${id}`, {}),
    update: ({ id, name }) => patch(`admin/files/${id}`, { name })
  }
};

export default endpoints;
